// Variables
$primarycolor: black;
$secondarycolor: #f8f9fa;
$accentcolor: #e55a00;
$thiscolor: #ff8674;
$ulBackgroundcolor: #eeeeee;
$libackgroundcolor: #d3d3d3;

// Mixins
@mixin shift-content-right {
  margin-left: 200px;
}

// Styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-body {
  min-height: 100vh;
}
#Offcanvas {
  width: 250px;
}

.bgImage {
  background-image: url("../public/images/geometricElementsBackground-01.jpg");
  background-size: cover;
  height: 500px;
}

.contactBgImage {
  background-image: url("../public/images/contactBackgroundImage-01.jpg");
  background-size: cover;
  height: 100vh;
}

.splash-image {
  background-color: #00d6cc;
  height: 200px;
}

.navbar {
  position: fixed;
  .nav-link {
    font-size: 0.8em;
    font-weight: bold;
    color: #4b4b4b;
    // margin-left: 30px;
  }
  .nav-item {
    width: 95%;
  }
}

.sidenav {
  position: fixed;
  ul {
    list-style-type: none;
    li {
      width: 90%;
      a {
        // width: 280px;
        // color: #d32929;
        font-size: 0.8em;
        // font-weight: bold;
        color: #4b4b4b;
      }
    }
  }
}

#getStarted {
  color: #00d6cc;
  border-color: #00d6cc;
  margin-left: 70px;
}

#contactMe {
  color: #00d6cc;
  border-color: #00d6cc;
  margin-left: 230px;
}

// #formCard {
//   max-width: 200px;
// }

form {
  p {
    margin: 10px 0 0 0px;
  }
  textarea {
    height: 150px;
  }
  .btn {
    color: white;
    background-color: #00d6cc;
  }
}

#myName {
  color: #00d6cc;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #eeeeee;
}

a:link {
  text-decoration: none;
}

.infoCard {
  @include shift-content-right();
  border: 1px solid #d3d3d3;
  .infoboximage {
    width: 60px;
    height: 60px;
    img {
      width: 50px;
    }
  }
}

.checkBoxes {
  max-width: 30rem;
  display: flex;
  .btn-group {
    flex-wrap: wrap;
  }
  label {
    height: 40px;
    width: 40px;
  }
}

#circle {
  background-color: cornflowerblue;
  width: 20px;
  height: 20px;
  border-radius: 30px;
}

#career {
  border-left: 2px dotted #dddcdc;
  margin-bottom: 20px;
}

#job {
  margin-left: 110px;
}
#job2 {
  margin-left: 183px;
  width: 314px;
}
#job3 {
  margin-left: 191px;
  width: 329px;
}
#job4 {
  margin-left: 128px;
}

#jobDescription {
  margin-left: 15px;
}

#footer {
  min-height: 500px;
}
